import "./index.scss";
import { FC, useState } from "react";
import { Button, message, Upload, UploadFile, UploadProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useActions } from "../hooks/useActions";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useParams } from "react-router-dom";
import { RcFile } from "antd/es/upload";
import { I18n, Translate } from "react-redux-i18n";
import getInvertedColorStyle from "../helpers/getInvertedColorStyle";

const ACCEPT_TYPES = ".png, .jpeg, .jpg";
const MAX_FILES_COUNT = 10;
const MAX_FILE_WEIGHT = 3145728;

const UploadFileComponent: FC = () => {
  const question = useSelector((state: RootState) => state.questions.question);
  const answer = useSelector((state: RootState) => state.questions.answer);
  const appColor = useSelector((state: RootState) => state.utils.appColor);

  const { answerQuestion, setUserAnswer, uploadFile } = useActions();
  const { instanceId } = useParams();
  const [files, setFiles] = useState<Array<UploadFile>>([]);

  const beforeUpload = (file: RcFile) => {
    if (file.size > MAX_FILE_WEIGHT) {
      message.error(I18n.t("maxFileWeightError"));
    }
    return false;
  };

  const onUpload: UploadProps["onChange"] = ({ fileList, file }) => {
    if (file?.size && file?.size <= MAX_FILE_WEIGHT) {
      setFiles(fileList);
    }
  };

  const isAnswerValid = () => {
    if (question?.answerTemplate?.customAnswerIsRequired) {
      return !!answer?.answerValue;
    }
    return true;
  };

  const styles = !question
    ? {}
    : {
        opacity: !isAnswerValid() ? 0.5 : 1,
        backgroundColor: appColor,
        borderColor: appColor,
      };

  const handleAnswer = async () => {
    if (!isAnswerValid()) {
      return message.error(I18n.t("answerRequired"));
    }
    if (instanceId && question?.questionId) {
      if (files?.length) {
        await uploadFile({
          files: files.map((it) => it?.originFileObj as RcFile),
          instanceId,
          questionId: question.questionId,
        });
      }
      await answerQuestion({
        answer,
        instanceId,
        questionId: question.questionId,
      });
      setUserAnswer({ answerValue: undefined, csiAnswers: [] });
      setFiles([]);
    }
  };

  return (
    <>
      <Upload
        fileList={files}
        onChange={onUpload}
        showUploadList={true}
        accept={ACCEPT_TYPES}
        maxCount={MAX_FILES_COUNT}
        beforeUpload={beforeUpload}
        disabled={files.length >= MAX_FILES_COUNT}
      >
        <Button disabled={files.length >= MAX_FILES_COUNT} icon={<PlusOutlined />}>
          <Translate value={"selectFile"} />
        </Button>
      </Upload>
      <div className={"upload-next-btn-container"}>
        <Button style={styles} type={"primary"} onClick={handleAnswer}>
          <span style={getInvertedColorStyle()}>
            <Translate value={"next"} />
          </span>
        </Button>
      </div>
    </>
  );
};

export default UploadFileComponent;
