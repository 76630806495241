import store from "../store";
const tinycolor = require("tinycolor2");

const getInvertedColorStyle = () => {
  const appColor = store.getState().utils.appColor;

  function isLightColor(color: string) {
    return tinycolor(color).isLight();
  }

  return { color: isLightColor(appColor) ? "black" : "white" };
};

export default getInvertedColorStyle;
