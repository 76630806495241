import "../index.scss";
import { FC, useCallback } from "react";
import { DatePicker, DatePickerProps, Input, Space, TimePicker } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useActions } from "../../../hooks/useActions";
import TextArea from "antd/es/input/TextArea";
import MaskedInput from "../../../components/maskedPhoneInput";
import { I18n } from "react-redux-i18n";
import { AnswerTemplate, CustomAnswerLineOptionsEnum, TypeEnum } from "../../../types/answerTemplate";
import { testValueUsingCustomRegExp } from "../../../helpers/testValueUsingCustomRegExp";
import { WebQuestion } from "../../../types/webQuestion";
import { Answer } from "../../../types/answer";
import { InputProps } from "antd/lib";
import dayjs from "dayjs";

const Wrapper: FC = () => {
  const { question, answer, answerTemplate, textFont, required } = useSelector((state: RootState) => {
    return {
      question: state.questions.question,
      answer: state.questions.answer,
      answerTemplate: state.questions.question?.answerTemplate,
      textFont: state.questions.question?.surveyDecor?.textFont,
      required: state.questions.question?.answerTemplate?.customAnswerIsRequired ?? false,
    };
  });

  return (
    <div className={"custom-component"} style={{ fontFamily: textFont }}>
      {required && <span className={"required-mark"}>*</span>}
      <CustomComponent answerRequired={required} question={question} answer={answer} answerTemplate={answerTemplate} />
    </div>
  );
};

type CustomComponentProps = {
  readonly question?: WebQuestion;
  readonly answer?: Answer;
  readonly answerTemplate?: AnswerTemplate;
  readonly answerRequired: boolean;
};

const CustomComponent: FC<CustomComponentProps> = (props) => {
  const { question, answer, answerTemplate, answerRequired } = props;
  const { setUserAnswer } = useActions();

  const isType = useCallback(
    (type$: TypeEnum) => {
      return type$ === answerTemplate?.type;
    },
    [answerTemplate?.type],
  );

  const inputProps: Pick<InputProps, "variant" | "style" | "value" | "placeholder" | "onChange"> = {
    variant: "borderless",
    style: { borderBottomRightRadius: 0, borderBottomLeftRadius: 0 },
    value: answer?.answerValue ?? "",
    placeholder: I18n.t("enterAnswer"),
    onChange: (e) => onChange(e.currentTarget.value),
  };

  const DatePickerProps: Pick<
    DatePickerProps,
    "value" | "onChange" | "inputReadOnly" | "needConfirm" | "style" | "variant" | "size" | "allowClear"
  > = {
    style: { width: "100%" },
    variant: "filled",
    size: "large",
    needConfirm: false,
    value: answer?.answerValue ? dayjs(answer.answerValue) : undefined,
    allowClear: !answerRequired,
    onChange: (_, dateStr) => {
      if (!dateStr) {
        return onChange("");
      }
      if (answer?.answerValue) {
        const [, time] = answer.answerValue.split(" ");
        const [newDate, newTime] = (dateStr as string).split(" ");
        onChange(newDate.concat(" ", time ?? newTime ?? "00:00"));
      } else {
        onChange(dateStr as string);
      }
    },
    inputReadOnly: true,
  };

  const onChange = useCallback(
    (answerValue: string) => {
      setUserAnswer({ answerValue, csiAnswers: [] });
    },
    [setUserAnswer],
  );

  const onChangeWithRegex = useCallback(
    (inputValue: string | undefined) => {
      if (!inputValue) {
        return;
      }

      const isInputValueValid = testValueUsingCustomRegExp({
        regexp: question?.answerTemplate?.customRegexp,
        value: inputValue,
      });

      if (isInputValueValid) {
        onChange(inputValue);
      }
    },
    [onChange],
  );

  if (isType(TypeEnum.NUMBER)) {
    return (
      <MaskedInput
        firstChar={"+"}
        {...(inputProps as any)}
        mask={"+9 (999) 999-9999"}
        onChange={(e) => {
          const value = e.target.value;
          onChange(value.slice(0, value.length - 1));
        }}
      />
    );
  } else if (isType(TypeEnum.EMAIL)) {
    return <Input {...inputProps} style={{ fontSize: 18, ...inputProps.style }} placeholder={"email@example.com"} />;
  } else if (isType(TypeEnum.IIN)) {
    return (
      <MaskedInput
        {...inputProps}
        placeholder={I18n.t("iin")}
        mask={"9 9 9 9 9 9 9 9 9 9 9 9"}
        onChange={(e) => onChange(e.target.value.slice(0, 23))}
      />
    );
  } else if (isType(TypeEnum.TEXT) && Boolean(answerTemplate?.customRegexp)) {
    return <Input {...inputProps} onChange={({ target }) => onChangeWithRegex(target?.value)} />;
  } else if (answerTemplate?.customAnswerLineOption === CustomAnswerLineOptionsEnum.MULTILINE) {
    return <TextArea {...(inputProps as any)} variant={"outlined"} autoSize={{ minRows: 4, maxRows: 6 }} />;
  } else if (isType(TypeEnum.DATE)) {
    return <DatePicker {...DatePickerProps} format={"YYYY-MM-DD"} />;
  } else if (isType(TypeEnum.DATETIME)) {
    return (
      <Space.Compact style={{ padding: "0 5px", width: "100%" }}>
        <DatePicker {...DatePickerProps} format={"YYYY-MM-DD"} />
        <TimePicker
          {...DatePickerProps}
          showSecond={false}
          allowClear={false}
          showMinute
          showHour
          disabled={!answer?.answerValue}
          onChange={(_, newTimeStr) => {
            if (answer?.answerValue) {
              const [date] = answer.answerValue.split(" ");
              onChange(date.concat(" ", newTimeStr as string));
            }
          }}
        />
      </Space.Compact>
    );
  }

  return <Input {...inputProps} />;
};

export default Wrapper;
