import { ChangeEvent, FC } from "react";
import UploadFileComponent from "../../../components/upload";
import { Input } from "antd";
import { useActions } from "../../../hooks/useActions";
import { I18n } from "react-redux-i18n";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const FileUploadComponent: FC = () => {
  const { setUserAnswer } = useActions();
  const answer = useSelector((state: RootState) => state.questions.answer);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserAnswer({ answerValue: e.currentTarget.value, csiAnswers: [] });
  };

  return (
    <div className={"upload-container"}>
      <Input
        style={{ marginBottom: 10, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
        placeholder={I18n.t("enterAnswer")}
        value={answer?.answerValue}
        variant={"borderless"}
        onChange={onChange}
        size={"large"}
      />
      <UploadFileComponent />
    </div>
  );
};

export default FileUploadComponent;
