import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SurveyLanguage } from "../../../types/surveyLanguage";

type StateType = {
  showLoader: boolean;
  appColor: string;
  lang?: SurveyLanguage;
  showErrorPage: boolean;
  errorCode: "404" | "500";
  logoUrl?: string;
};

const initialState: StateType = {
  showLoader: false,
  appColor: "",
  lang: undefined,
  showErrorPage: false,
  logoUrl: undefined,
  errorCode: "404",
};

const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    setShowLoader(state: StateType, action: PayloadAction<boolean>) {
      state.showLoader = action.payload;
    },
    setColor(state: StateType, action: PayloadAction<string>) {
      state.appColor = action.payload;
    },
    setLogoUrl(state: StateType, action: PayloadAction<string | undefined>) {
      state.logoUrl = action.payload;
    },
    setLang(state: StateType, action: PayloadAction<SurveyLanguage | undefined>) {
      state.lang = action.payload;
    },
    setShowError(state: StateType, action: PayloadAction<boolean>) {
      state.showErrorPage = action.payload;
    },
    setErrorCode(state: StateType, action: PayloadAction<"500" | "404">) {
      state.errorCode = action.payload;
    },
  },
});

export const { setErrorCode, setShowLoader, setColor, setLogoUrl, setLang, setShowError } = utilsSlice.actions;
export default utilsSlice.reducer;
