import { FC } from "react";
import "./index.scss";
import { Button, Dropdown } from "antd";
import { useActions } from "../hooks/useActions";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import getInvertedColorStyle from "../helpers/getInvertedColorStyle";
import { CaretDownOutlined } from "@ant-design/icons";
import { MenuInfo } from "rc-menu/lib/interface";

const LanguageChangeComponent: FC = () => {
  const { changeLang } = useActions();
  const { instanceId } = useParams();
  const { appLang, surveyLanguages } = useSelector((state: RootState) => ({
    appLang: state.utils.lang,
    surveyLanguages: state.surveys?.languages,
  }));
  const { color: invertedColor } = getInvertedColorStyle();

  const handleChange = (info: MenuInfo) => {
    if (instanceId) {
      changeLang({ instanceId, langCode: info.key });
    }
  };

  const menu = {
    onClick: handleChange,
    className: "header-actions-menu-btn",
    items: surveyLanguages?.map((l) => ({
      key: l?.langCode,
      label: l?.langName,
    })),
  };

  return (
    <div
      className={"language-changer"}
      style={{ boxShadow: `${invertedColor} 0 0px 3px 0`, "--inverted-color": invertedColor } as any}
    >
      {surveyLanguages?.length > 1 ? (
        <Dropdown menu={menu} trigger={["click"]}>
          <Button className="button" style={{ border: "none", width: 105 }}>
            <div className="lang-label">{appLang?.langName}</div>
            <CaretDownOutlined />
          </Button>
        </Dropdown>
      ) : null}
    </div>
  );
};

export default LanguageChangeComponent;
