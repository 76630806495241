import { Languages } from "../types/surveyLanguage";

export const getClientLanguageOrDefault = (surveyLanguages: string[]) => {
  const firstLangCode = surveyLanguages.at(0) ?? Languages.RU;
  const clientLanguage = navigator.language.substring(0, 2).toUpperCase();
  const isClientLanguageAllowed = !surveyLanguages.length
    ? Boolean(Object.values(Languages).find((it) => it === clientLanguage))
    : Boolean(surveyLanguages.map((it) => it).includes(clientLanguage));

  return isClientLanguageAllowed ? clientLanguage : firstLangCode;
};
