import "../index.scss";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getCurrentTextTranslation } from "../../../helpers/getCurrentTextTranslation";

const TitleComponent: FC = () => {
  const question = useSelector((state: RootState) => state.questions.question);

  return (
    <div className={"question-container"}>
      {question?.surveyDecor?.title && (
        <span
          className={question?.surveyDecor?.titleFont ? "disable-global-font" : ""}
          style={{
            fontSize: question?.surveyDecor?.titleSize || 24,
            fontFamily: question?.surveyDecor?.titleFont,
            color: question?.surveyDecor?.titleColor,
            marginBottom: 10,
            fontWeight: 700,
            whiteSpace: "pre-line",
          }}
        >
          {question.surveyDecor.title}
        </span>
      )}
      <span
        className={question?.surveyDecor?.textColor ? "disable-global-font" : ""}
        style={{
          fontSize: question?.surveyDecor?.textSize || 18,
          fontFamily: question?.surveyDecor?.textFont,
          color: question?.surveyDecor?.textColor,
          fontWeight: "lighter",
          marginBottom: 20,
          whiteSpace: "pre-line",
        }}
      >
        {getCurrentTextTranslation(question?.textTranslations)}
      </span>
    </div>
  );
};

export default TitleComponent;
