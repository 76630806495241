import { NpsParams } from "./npsParams";

export type NpsInfoParams = {
  readonly icon?: NpsIconEnum;
  readonly maxGrade: 5 | 10;
  readonly npsParams?: Array<NpsParams>;
  readonly type: NpsTypeEnum;
};

export enum NpsTypeEnum {
  DROPDOWN = "DROPDOWN",
  RADIO_BUTTONS = "RADIO_BUTTONS",
  RATING = "RATING",
  SLIDER = "SLIDER",
}

export enum NpsIconEnum {
  STAR = "star",
  HEART = "heart",
  SMILE = "smile",
  NUMERIC = "numeric",
}
