import "./index.scss";
import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Translate } from "react-redux-i18n";

const FooterComponent: FC = () => {
  const { instanceId } = useParams();
  const redirect = useCallback(() => {
    window.open(`https://surveysuite.io/?utm_source=${instanceId}&utm_medium=cpa&utm_campaign=for-lead`, "_blank");
  }, [instanceId]);

  return (
    <div className="footer">
      <Translate value={"footer.beforeText"}></Translate>
      <span className="link" onClick={redirect}>
        surveysuite.io
      </span>
      <Translate value={"footer.afterText"}></Translate>
    </div>
  );
};

export default FooterComponent;
