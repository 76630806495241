import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SurveyLanguage } from "../../../types/surveyLanguage";

type StateType = {
  languages: Array<SurveyLanguage>;
};

const initialState: StateType = {
  languages: [],
};

const surveysSlice = createSlice({
  name: "surveys",
  initialState,
  reducers: {
    setLanguages(state: StateType, action: PayloadAction<Array<SurveyLanguage>>) {
      state.languages = action.payload;
    },
  },
});

export const { setLanguages } = surveysSlice.actions;
export default surveysSlice.reducer;
