import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setColor,
  setErrorCode as setErrorCode$,
  setLang,
  setLogoUrl,
  setShowError,
  setShowLoader,
} from "../store/reducers/utils";
import { SurveyLanguage } from "../types/surveyLanguage";
import { setLocale } from "react-redux-i18n";

export const setLoader = createAsyncThunk<void, boolean>("utils/setLoader", (show, { dispatch }) => {
  dispatch(setShowLoader(show));
});

export const setErrorCode = createAsyncThunk<void, "500" | "404">("utils/setErrorCode", (errorCode, { dispatch }) => {
  dispatch(setErrorCode$(errorCode));
});

export const setLanguage = createAsyncThunk<void, SurveyLanguage>("utils/setLanguage", (lang, { dispatch }) => {
  dispatch(setLang(lang));
  dispatch(setLocale(lang.langCode));
});

export const setShowErrorPage = createAsyncThunk<void, boolean>("utils/setShowErrorPage", (show, { dispatch }) => {
  dispatch(setShowError(show));
});

export const setAppColor = createAsyncThunk<void, string>("utils/setAppColor", (color, { dispatch }) => {
  dispatch(setColor(color));
});

export const setAppLogoUrl = createAsyncThunk<void, string | undefined>("utils/setAppLogoUrl", (url, { dispatch }) => {
  dispatch(setLogoUrl(url));
});
