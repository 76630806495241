import { SurveyLanguage } from "../types/surveyLanguage";
import store from "../store";
import { setSurveyLanguages } from "../services/surveysService";

export function manageSurveyLanguages(languages: Array<SurveyLanguage>) {
  const stored = store.getState().surveys.languages;

  if (!stored.length) {
    store.dispatch(setSurveyLanguages(languages));
  }
}
