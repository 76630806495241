import { WebQuestion } from "../types/webQuestion";

export function manageMetaData(data: WebQuestion) {
  if (data?.surveyMetadata?.metaTitle) {
    const meta = document.getElementById("doc-title") as HTMLMetaElement;
    const title = document.getElementById("doc-main-title") as HTMLTitleElement;

    const title$ =
      data.surveyMetadata.textTranslations?.find((it) => it.language.langCode === data.survLang?.langCode)?.text ??
      data.surveyMetadata.metaTitle ??
      "SurveySuite";
    meta.content = title$;
    title.textContent = title$;
  }

  if (data?.surveyMetadata?.metaDescription) {
    const meta = document.getElementById("doc-description") as HTMLMetaElement;
    meta.content = data.surveyMetadata.metaDescription;
  }

  if (data?.faviconPath) {
    changeAppIcon(data.faviconPath, "icon");
    changeAppIcon(data.faviconPath, "apple-touch-icon");
  }

  if (data?.themeColor) {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor instanceof HTMLMetaElement) {
      metaThemeColor.content = data?.themeColor;
    }
  }
}

function changeAppIcon(path: string, rel: string) {
  let icon = document.querySelector(`link[rel=${rel}]`) as HTMLLinkElement | null;

  if (icon !== null) {
    if (icon.href !== path) {
      icon.href = path;
    }
  } else {
    icon = document.createElement("link");
    icon.rel = rel;
    icon.href = path;
    document.head.appendChild(icon);
  }
}
