import { FC } from "react";
import { QuestionMediaFile, QuestionMediaFileTypesEnum } from "../../../types/questionMediaFile";

type Props = {
  readonly data: QuestionMediaFile;
};

const MediaFile: FC<Props> = ({ data }) => {
  if (data.contentType === QuestionMediaFileTypesEnum.VIDEO) {
    return (
      <video
        style={{ borderRadius: 8, width: "inherit", objectFit: "contain", objectPosition: "center" }}
        src={data.url}
        controls
        autoPlay
      >
        <source src={data.url} type="video/mp4" />
        Ваш браузер не поддерживает видео.
      </video>
    );
  }
  return (
    <img
      alt={"banner"}
      src={data.url}
      style={{
        borderRadius: 8,
        width: "inherit",
        objectFit: "contain",
        objectPosition: "center",
      }}
    />
  );
};

const MediaFileWrapper: FC<Props> = ({ data }) => {
  return (
    <div style={{ display: "flex", width: "100%", height: "auto" }}>
      <MediaFile data={data} />
    </div>
  );
};

export default MediaFileWrapper;
