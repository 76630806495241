import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WebQuestion } from "../../../types/webQuestion";
import { Answer } from "../../../types/answer";

type StateType = {
  question?: WebQuestion;
  answer?: Answer;
  customAnswer?: string;
};

const initialState: StateType = {
  question: undefined,
  answer: undefined,
  customAnswer: undefined,
};

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    setQuestion(state: StateType, action: PayloadAction<WebQuestion | undefined>) {
      state.question = action.payload;
    },
    setAnswer(state: StateType, action: PayloadAction<Answer>) {
      return {
        ...state,
        answer: {
          ...state.answer,
          ...action.payload,
        },
      };
    },
    setCustomAnswer(state: StateType, action: PayloadAction<string | undefined>) {
      return {
        ...state,
        customAnswer: action.payload,
        answer: {
          ...state.answer,
          answerValue: action.payload,
        },
      };
    },
  },
});

export const { setQuestion, setAnswer, setCustomAnswer } = questionsSlice.actions;
export default questionsSlice.reducer;
