import "../index.scss";
import { FC, useCallback, useEffect, useState } from "react";
import { Checkbox } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import getChoiceTitle from "../../../helpers/getChoiceTitle";
import { sortByOrder } from "../../../helpers/sortByOrder";
import { useActions } from "../../../hooks/useActions";

const CheckboxGroup = Checkbox.Group;

const MultipleChoiceComponent: FC = () => {
  const question = useSelector((state: RootState) => state.questions.question);
  const options = useSelector((state: RootState) =>
    (state.questions.question?.answerTemplate?.answerChoices ?? [])
      .filter((ac) => ac)
      .sort(sortByOrder)
      .map(getChoiceTitle)
      .filter((it) => !!it.choiceId),
  );

  const [checkedList, setCheckedList] = useState<number[]>([]);
  const { setUserAnswer } = useActions();

  const onChange = useCallback(
    (list: number[]) => {
      setCheckedList(list);
      setUserAnswer({ choices: list });
    },
    [setCheckedList, setUserAnswer, checkedList],
  );

  useEffect(() => {
    setCheckedList([]);
  }, [question?.questionId]);

  return (
    <CheckboxGroup value={checkedList} onChange={onChange} style={{ display: "grid", gap: 15 }} className={"checkbox"}>
      {options.map((opt) => (
        <Checkbox
          key={opt.choiceId}
          value={opt.choiceId}
          id={String(opt.choiceId)}
          checked={checkedList.includes(opt.choiceId)}
        >
          <span
            style={{ fontFamily: question?.surveyDecor?.textFont }}
            className={question?.surveyDecor?.textFont ? "disable-global-font" : ""}
          >
            {opt.label}
          </span>
        </Checkbox>
      ))}
    </CheckboxGroup>
  );
};

export default MultipleChoiceComponent;
