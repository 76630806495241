import { createAsyncThunk } from "@reduxjs/toolkit";
import { SurveyLanguage } from "../types/surveyLanguage";
import { setLanguages } from "../store/reducers/surveys";
import { requestApi } from "./requestApi";
import { POST } from "../types/httpMethod";
import { manageCookies, setCookie } from "../helpers/cookiesManager";
import { APP_PREFIX } from "../helpers";
import { ReceiverTypesEnum } from "../types/receiverTypesEnum";
import { changeReceiverType, saveDevice, saveUserMetadata } from "./requests";
import { UserMetadataType } from "../types/UserMetadataType";
import { isMobile } from "react-device-detect";
import { DeviceEnum } from "../types/device";
import { SurveyVariants } from "../types/surveyVariants";
import { isInstanceEmulator } from "../helpers/isEmulator";

export const startAnonymousSurvey = createAsyncThunk<
  void,
  {
    readonly receiver: string;
    readonly surveyId: string;
    readonly navigate: (url: string) => void;
    readonly variant: string | null;
    readonly metadata: UserMetadataType;
  }
>("surveys/startAnonymousSurvey", async (data) => {
  try {
    const { metadata, variant, ...instance } = data;
    const isEmulator = Boolean(variant && variant === SurveyVariants.EMULATOR);

    let instanceId = await requestApi(POST, "/survey", instance, true, false, {}, isEmulator);

    if (isEmulator) {
      setCookie(SurveyVariants.EMULATOR, instanceId);
    } else {
      instanceId = manageCookies(instance.surveyId, instanceId);
    }

    if (variant && variant === SurveyVariants.FRAME) {
      changeReceiverType(instanceId, ReceiverTypesEnum.IFRAME);
    }

    if (!isEmulator && !isInstanceEmulator(instanceId)) {
      await saveDevice(instanceId, isMobile ? DeviceEnum.MOBILE : DeviceEnum.DESKTOP);
      saveUserMetadata(instanceId, metadata);
    }

    instance.navigate(`${APP_PREFIX}/${instanceId}`);
  } catch (e) {
    throw e;
  }
});

export const setSurveyLanguages = createAsyncThunk<void, Array<SurveyLanguage>>(
  "surveys/setSurveyLanguages",
  (languages, { dispatch }) => {
    dispatch(setLanguages(languages));
  },
);
