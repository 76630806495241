import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { FrownOutlined, HeartFilled, MehOutlined, SmileOutlined, StarFilled } from "@ant-design/icons";
import { Rate } from "antd";
import { NpsIconEnum } from "../../../types/npsInfoParams";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

export const smileIcons: Record<number, ReactNode> = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};

type Props = {
  readonly iconType?: NpsIconEnum;
  readonly maxValue: 5 | 10;
  readonly onChange: (value?: string) => void;
};

const Rating: FC<Props> = ({ iconType, maxValue, onChange }) => {
  const [val, setVal] = useState<number | undefined>();
  const qId = useSelector((state: RootState) => state.questions.question?.questionId);

  const isIcon = useCallback(
    (type: NpsIconEnum) => {
      return iconType === type;
    },
    [iconType],
  );

  const handleChange = useCallback(
    (value$: number) => {
      setVal(value$);
      onChange(value$ ? String(value$ - 1) : undefined);
    },
    [setVal],
  );

  const getCharacter = useCallback(
    (index?: number) => {
      if (!index) return;
      if (isIcon(NpsIconEnum.NUMERIC)) {
        return index;
      } else if (isIcon(NpsIconEnum.SMILE)) {
        return smileIcons[index];
      } else if (isIcon(NpsIconEnum.HEART)) {
        return <HeartFilled />;
      }
      return <StarFilled />;
    },
    [isIcon],
  );

  useEffect(() => {
    setVal(0);
  }, [qId]);

  return (
    <Rate
      value={val}
      allowHalf={false}
      onChange={handleChange}
      count={(isIcon(NpsIconEnum.SMILE) ? 5 : maxValue) + 1}
      character={({ index }) => getCharacter(index)}
      style={{ fontSize: isIcon(NpsIconEnum.NUMERIC) || maxValue === 5 ? 40 : 25 }}
    />
  );
};

export default Rating;
