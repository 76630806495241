import { FC, ReactNode } from "react";

const AppContainer: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div
      style={{
        maxWidth: 700,
        width: "100%",
        display: "flex",
        height: "100dvh",
        margin: "0 auto",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {children}
    </div>
  );
};

export default AppContainer;
