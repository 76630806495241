import { FC, useEffect } from "react";
import ErrorPageLayout from "../components/errorPageLayout";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useActions } from "../hooks/useActions";
import { getClientLanguageOrDefault } from "../helpers/clientLanguage";

type Props = {
  readonly errorCode: "404" | "500";
};

const staticProps = {
  "404": {
    imageUrl: "/assets/images/error_404.png",
    descriptionKey: "404ErrorDescription",
  },
  "500": {
    imageUrl: "/assets/images/error_500.png",
    descriptionKey: "500ErrorDescription",
  },
};

const ErrorPage: FC<Props> = ({ errorCode }) => {
  const appLang = useSelector((state: RootState) => state.utils.lang);
  const { setLanguage } = useActions();

  useEffect(() => {
    if (!appLang) {
      const newLanguage = getClientLanguageOrDefault([]);
      setLanguage({ langCode: newLanguage, langName: newLanguage });
    }
  }, []);

  return (
    <ErrorPageLayout description={staticProps[errorCode].descriptionKey} imageUrl={staticProps[errorCode].imageUrl} />
  );
};

export default ErrorPage;
