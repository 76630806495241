import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import questionsReducer from "./reducers/questions";
import surveysReducer from "./reducers/surveys";
import utilsReducer from "./reducers/utils";
import { i18nReducer, loadTranslations, syncTranslationWithStore } from "react-redux-i18n";
import { translations } from "../helpers/i18/translations";

const store = configureStore({
  reducer: {
    questions: questionsReducer,
    surveys: surveysReducer,
    utils: utilsReducer,
    i18n: i18nReducer,
  },
});

export default store;

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
