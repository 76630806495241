import "./index.scss";
import { FC } from "react";
import InputMask from "react-input-mask";
import { Input } from "antd";
import { InputProps } from "antd/lib";

export type MaskedInputProps = Pick<InputProps, "value" | "placeholder" | "style" | "onChange"> & {
  readonly mask: string;
  readonly firstChar?: string;
};

const MaskedInput: FC<MaskedInputProps> = (props) => {
  return (
    <InputMask
      mask={props.mask}
      alwaysShowMask={false}
      onChange={props.onChange}
      className={"masked-input"}
      placeholder={props.placeholder}
      value={(props?.value ?? props?.firstChar) as string | undefined}
    >
      <Input variant={"borderless"} style={{ fontSize: 20, ...props.style }} size={"large"} />
    </InputMask>
  );
};

export default MaskedInput;
