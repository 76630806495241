type Prop = { label: string; choiceId: number };

const filterChoices = (options?: Array<Prop>): Array<Prop> => {
  return (
    options?.filter((item, index) => {
      return options?.map((it) => it).indexOf(item) === index;
    }) ?? []
  );
};

export default filterChoices;
