import { useAppDispatch } from "../store";
import { bindActionCreators } from "redux";

import * as QuestionsService from "../services/questionsService";
import * as SurveysService from "../services/surveysService";
import * as UtilsService from "../services/utilsService";

export const allActionCreators = {
  ...QuestionsService,
  ...SurveysService,
  ...UtilsService,
};

export const useActions = () => {
  const dispatch = useAppDispatch();
  return bindActionCreators(allActionCreators, dispatch);
};
