import { TextTranslationType } from "../types/textTranslation";
import store from "../store";

export function getCurrentTextTranslation(languages?: Array<TextTranslationType>) {
  const lang = store.getState().utils.lang;
  const foundTranslation = languages?.find((it) => it?.language?.langCode === lang?.langCode);
  return foundTranslation?.text ?? languages?.at(0)?.text ?? "";
}

export function getCurrentChoiceTextTranslation(choiceId: number, languages?: Array<TextTranslationType>) {
  const lang = store.getState().utils.lang;
  const foundTranslation = languages?.find((it) => it?.language?.langCode === lang?.langCode);
  return { choiceId: choiceId, label: foundTranslation?.text ?? languages?.at(0)?.text ?? "" };
}
