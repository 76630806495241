import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Languages } from "../../../types/surveyLanguage";

const EndBlock: FC = () => {
  const question = useSelector((state: RootState) => state.questions.question);
  const currentLanguage = useSelector((state: RootState) => state.utils.lang);

  const fontObj = {
    className: question?.surveyDecor?.textFont ? "disable-global-font" : "",
    style: {
      fontFamily: question?.surveyDecor?.textFont,
      fontSize: 16,
    },
  };

  const linkProps = {
    ...fontObj,
    href: "https://surveysuite.io/?utm_source=svi_6lep5avc3w&utm_medium=cpa&utm_campaign=for-lead",
    style: { ...fontObj, fontWeight: 600 },
    rel: "noreferrer",
    target: "_blank",
  };

  const SSLink = () => <a {...linkProps}>surveysuite.io</a>;

  if (currentLanguage?.langCode === Languages.EN) {
    return (
      <div {...fontObj}>
        Survey completed. Visit <SSLink /> for more information about Survey Suite service.
      </div>
    );
  } else if (currentLanguage?.langCode === Languages.KK) {
    return (
      <div {...fontObj}>
        Сауалнама аяқталды. Survey Suite қызметі туралы қосымша ақпарат алу үшін <SSLink /> веб-сайтқа кіріңіз.
      </div>
    );
  }
  return (
    <div {...fontObj}>
      Опрос завершен. Узнайте больше о сервисе Survey Suite на сайте <SSLink />.
    </div>
  );
};

export default EndBlock;
