import "./index.scss";
import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import LanguageChangeComponent from "./changeLang";
import { Progress } from "antd";

const HeaderComponent: FC = () => {
  const appColor = useSelector((state: RootState) => state.utils.appColor);
  const logoUrl = useSelector((state: RootState) => state.utils.logoUrl);
  const question = useSelector((state: RootState) => state.questions.question);

  return (
    <div className={"header-container"}>
      <div className={"header"} style={{ backgroundColor: question ? appColor : "lightgray" }}>
        {question && <img className={"logo"} src={logoUrl} alt="logo" />}
        <LanguageChangeComponent />
      </div>
      {question && question?.surveyDecor?.showProgress && (
        <Progress
          style={{
            top: 70,
            padding: "0px 20px",
            background: "#ffffff",
            paddingTop: 5,
            zIndex: 99,
            paddingBottom: 5,
          }}
          percent={question.progress}
          strokeWidth={16}
          success={{
            percent: question.progress,
            strokeColor: question.progress < 100 ? appColor : "#52c41a",
          }}
        />
      )}
    </div>
  );
};
export default HeaderComponent;
