import "../index.scss";
import { ChangeEvent, FC } from "react";
import { Input } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useActions } from "../../../hooks/useActions";
import { I18n } from "react-redux-i18n";
import { getCurrentTextTranslation } from "../../../helpers/getCurrentTextTranslation";

const CustomAnswerComponent: FC = () => {
  const { question, answer } = useSelector((state: RootState) => ({
    question: state.questions.question,
    answer: state.questions.customAnswer,
  }));

  const { setUserCustomAnswer } = useActions();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserCustomAnswer(e.currentTarget.value);
  };

  return (
    <div className={"custom-answer"} style={{ fontFamily: question?.surveyDecor?.textFont }}>
      {getCurrentTextTranslation(question?.answerTemplate?.textTranslations)}
      <Input
        value={answer}
        size={"large"}
        onChange={onChange}
        variant={"borderless"}
        placeholder={I18n.t("yourAnswer")}
      />
    </div>
  );
};

export default CustomAnswerComponent;
