import { DefaultOptionType } from "antd/lib/select";
import store from "../store";
import { CsiScaleEnum } from "../types/answerTemplate";
import { I18n } from "react-redux-i18n";

export const criteriaOptions = (): DefaultOptionType[] | undefined => {
  const question = store.getState().questions.question;

  function isScale(scale: CsiScaleEnum) {
    return question?.answerTemplate?.csiScale === scale;
  }

  if (isScale(CsiScaleEnum.BOOLEAN)) {
    return [
      {
        value: "0",
        label: I18n.t("no"),
      },
      {
        value: "1",
        label: I18n.t("yes"),
      },
    ];
  }

  return Array.from(Array(isScale(CsiScaleEnum.FIVE_POINTS) ? 5 : 10).keys()).map((key) => ({
    value: key + 1,
    label: key + 1,
  }));
};
