export type SurveyLanguage = {
  readonly langCode: string;
  readonly langName: string;
};

export enum Languages {
  RU = "RU",
  EN = "EN",
  KK = "KK",
  TR = "TR",
  FR = "FR",
  UZ = "UZ",
}
