import { TextTranslationType } from "./textTranslation";

export type NpsParams = {
  readonly grade: GradeEnum;
  readonly textEn?: string;
  readonly textKk: string;
  readonly textRu: string;
  readonly textTranslations?: Array<TextTranslationType>;
};

export enum GradeEnum {
  MAX_GRADE = "MAX_GRADE",
  MIN_GRADE = "MIN_GRADE",
}
