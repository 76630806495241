import axios from "axios";
import store from "../store";
import { GET } from "../types/httpMethod";
import { setErrorCode, setLoader, setShowErrorPage } from "./utilsService";
import { RcFile } from "antd/es/upload";
import { isInstanceEmulator } from "../helpers/isEmulator";

const axiosInstance = axios.create({
  baseURL: window._env_.API_BASE_URL + window._env_.API_PATH,
});

export const requestApi = async (
  method: string,
  url: string,
  data: any,
  checkLanguage?: boolean,
  multipart = false,
  params: any = {},
  isEmulator = false,
) => {
  const isCurrentSurveyEmulator = isInstanceEmulator(url);

  const config: {
    readonly method: string;
    readonly url: string;
    params: any;
    data: any;
    headers: any;
  } = {
    method: method,
    url: isEmulator || isCurrentSurveyEmulator ? "/emulator" + url : url,
    params: params,
    data: {},
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (method === GET) {
    config.params = { ...config.params, ...data };
  } else {
    config.data = data;
  }

  if (checkLanguage) {
    config.headers["accept-language"] = navigator.language.substring(0, 2).toUpperCase();
  }

  if (multipart) {
    const formData = new FormData();
    if (Array.isArray(data)) {
      data.forEach((file: RcFile) => {
        formData.append("files", file);
      });
    }
    config.headers = {
      ...config.headers,
      "Content-Type": undefined,
      enctype: "multipart/form-data",
    };
    config.data = formData;
  }

  try {
    store.dispatch(setLoader(true));
    const response = await axiosInstance.request(config);
    return response.data;
  } catch (e: any) {
    const status = String(e.response?.status);
    const errorCode = status !== "500" && status !== "404" ? "500" : status;
    store.dispatch(setShowErrorPage(true));
    store.dispatch(setErrorCode(errorCode));
    throw e;
  } finally {
    store.dispatch(setLoader(false));
  }
};
