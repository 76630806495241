type Props = {
  readonly regexp?: string;
  readonly value: string;
};

export const testValueUsingCustomRegExp = ({ regexp, value }: Props) => {
  let isValidRegexp = true;
  let regex = null;
  const trimmedRegexp = regexp ? regexp.replace(/^\/|\/$/g, "") : "";

  try {
    regex = regexp ? new RegExp(trimmedRegexp) : null;
  } catch (e) {
    isValidRegexp = false;
  }

  if (isValidRegexp && regex) {
    return regex.test(value);
  }
  return false;
};
