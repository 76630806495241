export const manageCookies = (surveyId: string, id: string) => {
  const cookie = getCookie(surveyId);
  let instanceId: string | undefined = id;

  if (!cookie) {
    setCookie(surveyId, instanceId);
  } else {
    instanceId = cookie?.value;
  }

  return instanceId;
};

export const setCookie = (key: string, value: string) => {
  const exDate = new Date();
  exDate.setDate(exDate.getDate() + 1);
  document.cookie = encodeURIComponent(key) + "=" + encodeURIComponent(value) + "; expires=" + exDate.toUTCString();
};

export const getCookie = (key: string) => {
  return document.cookie
    .split("; ")
    .map((it) => {
      const row = it.split("=");
      return { key: row.at(0), value: row.at(-1) };
    })
    .find((it) => it?.key === key);
};
