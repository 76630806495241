import { AnswerChoice } from "../types/answerChoice";
import { getCurrentChoiceTextTranslation } from "./getCurrentTextTranslation";

const getChoiceTitle = (answerChoice: AnswerChoice) => {
  if (!answerChoice?.answerChoiceId) {
    return { label: "", choiceId: -1 };
  }
  return getCurrentChoiceTextTranslation(answerChoice.answerChoiceId, answerChoice?.textTranslations);
};

export default getChoiceTitle;
