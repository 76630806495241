import React, { FC } from "react";
import "./index.scss";
import "antd/dist/reset.css";
import AppRoutes from "./routes";
import AppProvider from "./components/appProvider";
import AppContainer from "./components/appContainer";

const App: FC = () => {
  return (
    <AppContainer>
      <AppProvider>
        <AppRoutes />
      </AppProvider>
    </AppContainer>
  );
};

export default App;
