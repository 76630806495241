import { FC } from "react";
import "./index.scss";
import { Button, ConfigProvider } from "antd";
import { Translate } from "react-redux-i18n";

type Props = {
  readonly imageUrl: string;
  readonly description?: string;
};

const defaultProps = {
  imageUrl: "/assets/images/error_404.png",
  description: "К сожалению, запрашиваемая страница не найдена...",
};

const navigateToLending = () => {
  window.open(`https://surveysuite.io/?utm_medium=cpa&utm_campaign=for-lead`, "_blank");
};

const ErrorPageLayout: FC<Props> = (props) => {
  const { imageUrl, description } = { ...defaultProps, ...props };
  return (
    <div className={"error-page-layout"}>
      <div className="image">
        <img src={imageUrl} alt="error" />
      </div>
      <div className="description">
        <h3>
          <Translate value={description} />
        </h3>
      </div>
      <ConfigProvider theme={{ token: { colorPrimary: "#00AA5C" } }}>
        <Button size={"large"} type={"primary"} style={{ width: "calc(100% - 60px)" }} onClick={navigateToLending}>
          <Translate value={"errorPageButtonLabel"} />
        </Button>
      </ConfigProvider>
    </div>
  );
};

export default ErrorPageLayout;
