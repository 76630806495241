import { requestApi } from "./requestApi";
import { PATCH, POST, PUT } from "../types/httpMethod";
import { setLocale } from "react-redux-i18n";
import { getNextQuestion } from "./questionsService";
import store from "../store";
import { DeviceEnum } from "../types/device";
import { ReceiverTypesEnum } from "../types/receiverTypesEnum";
import { UserMetadataType } from "../types/UserMetadataType";

export const changeLanguage = async (instanceId: string, langCode: string) => {
  try {
    const dispatch = store.dispatch;
    await requestApi(PUT, `/survey/${instanceId}/${langCode}`, {});
    await dispatch(getNextQuestion({ instanceId }));
    dispatch(setLocale(langCode));
    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};

export const saveDevice = async (instanceId: string, deviceType: DeviceEnum) => {
  return requestApi(POST, `/survey/${instanceId}`, { deviceType });
};

export const changeReceiverType = (instanceId: string, type: ReceiverTypesEnum) => {
  requestApi(PATCH, `/survey/${instanceId}/receiver-type`, {}, false, false, { type }).catch(console.log);
};

export const saveUserMetadata = (instanceId: string, metadata: UserMetadataType) => {
  requestApi(POST, `/survey/${instanceId}/metadata`, { metadata }).catch(console.log);
};
