import store from "../store";
import { CsiAnswer, ScaleEnum } from "../types/csiAnswer";
import { sortByOrder } from "./sortByOrder";
import { getCurrentTextTranslation } from "./getCurrentTextTranslation";

export const criteriaAnswers = (): Array<CsiAnswer> => {
  const question = store.getState().questions.question;

  return (
    question?.answerTemplate?.csiParams
      ?.map((csi) => csi)
      .sort(sortByOrder)
      .map(
        (csi) =>
          ({
            paramName: getCurrentTextTranslation(csi.textTranslations),
            scale: question?.answerTemplate?.csiScale || ScaleEnum.TEN_POINTS,
            criterias: question?.answerTemplate?.criteria
              ?.map((cr) => cr)
              .sort(sortByOrder)
              .map((criteria) => ({
                name: getCurrentTextTranslation(criteria?.textTranslations),
                score: undefined,
              })),
          } as CsiAnswer),
      ) || []
  );
};
