import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import SurveyPage from "../pages/survey";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { APP_PREFIX } from "../helpers";
import ErrorPage from "../pages/errorPage";

const AppRoutes: FC = () => {
  const { showErrorPage, errorCode } = useSelector((state: RootState) => state.utils);
  return (
    <Routes>
      {!showErrorPage && <Route path={`/${APP_PREFIX}/:instanceId`} element={<SurveyPage />} />}
      <Route path="*" element={<ErrorPage errorCode={errorCode} />} />
    </Routes>
  );
};

export default AppRoutes;
